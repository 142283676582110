import React, {useEffect, useState} from 'react';
import {CardElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import BreadCrumb from "../Components/BreadCrumb";
import '../Styles/_checkout.scss';
import {useCount} from "../Contexts/cart-context";
import {getCartPrice, getCartPriceWithTaxes} from "../utils/productUtils";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import CartServices from "../Services/cart";
import {getDiscountAmount, getFinalPrice, getSubTotal} from "../utils/cart";
import {constants} from "../constants";
import  { ReactComponent as Check } from '../assets/check.svg';


const CheckoutPage = () => {
  const {register, handleSubmit, watch, formState: {errors}} = useForm();

  const [isShipping, setIsShipping] = useState(true)
  // const [promoCode, setPromoCode] = useState('')
  //
  // const [validPromoCode, setValidPromoCode] = useState(null)

  const {
    state, dispatch
  } = useCount()

  const [subTotal, setSubtotal] = useState(getSubTotal(state.products, null, isShipping))
  // const [total, setTotal] = useState(0)
  // const [discount, setDiscount] = useState(null)

  // const [priceWithTaxes, setPriceWithTaxes] = useState(Number(getCartPriceWithTaxes(state.products)))

  const proceedToCheckout = (data) => {
    const body = {products: state.products, checkout: {isShipping, ...data},}
    body.checkout.address +=
    CartServices.checkout(body)
      .then((res) => {
        const url = res.data
        window.location.href = url;
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //
  // const onPromoCodeChange = (event) => {
  //   setPromoCode(event.target.value)
  // }

  // const [cartPrice, setCartPrice] = useState(getCartPrice(state.products))


  // useEffect(() => {
  //   setSubtotal(subTotal-discount > 60 ? subTotal - discount : subTotal - discount + 10)
  // }, [discount])

  useEffect(() => {
    setSubtotal(getSubTotal(state.products, null, isShipping))
  }, [isShipping])

  // const applyPromoCode = (event) => {
  //   event.preventDefault()
  //   CartServices.applyPromoCode(promoCode)
  //     .then((res) => {
  //       setValidPromoCode(res.data)
  //       setDiscount(getDiscountAmount(state.products, res.data))
  //       // TODO add snackbar display
  //     })
  //     .catch((err) => {
  //       // TODO - handle error here
  //       console.log(err)
  //     })
  // }

  const shippingInfos = () => {
    return <div className="checkout__address">
      <h4>Adresse de livraison </h4>
      <div className="row">
        <div className="col-lg-12">
          <div className="input__item double__input">
            <p>Adresse<span>*</span></p>
            <input type="text"
                   name={'address'}
                   className={errors?.address?.type === "required" ? 'required' : ''}  {...register("address", {required: isShipping})}
                   placeholder="Adresse"/>
            {errors?.address?.type === "required" &&
            <p className={'missing-field-error'}>Champs obligatoire</p>}

            <input type="text"
                   name={'address2'}
                   {...register("address2")}
                   placeholder="Appartement, suite, unité etc ( optionnel )"/>
          </div>
          <div className="input__item">
            <p>Ville<span>*</span></p>
            <input type="text"
                   name={'city'}
                   className={errors?.city?.type === "required" ? 'required' : ''}  {...register("city", {required: isShipping})}
                   placeholder={'Ville'}/>
            {errors?.city?.type === "required" &&
            <p className={'missing-field-error'}>Champs obligatoire</p>}

          </div>
          <div className="input__item">
            <p>Pays<span>*</span></p>
            <input type="text"{...register("country")} disabled={true} value={'Canada'}
                   contentEditable={false}/>
          </div>
          <div className="input__item">
            <p>Code postal<span>*</span></p>
            <input type="text"
                   name={'postal_code'}
                   className={errors?.postalCode?.type === "required" || errors.postalCode?.type === 'pattern' ? 'required' : ''}  {...register("postalCode", {
              required: isShipping,
              pattern: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/

            })} placeholder={'Code postal'}/>
            {errors?.postalCode?.type === "required" &&
            <p className={'missing-field-error'}>Champs obligatoire</p>}
            {errors?.postalCode?.type === "pattern" &&
            <p className={'missing-field-error'}>Code postal invalide</p>}
          </div>
        </div>
      </div>
    </div>
  }

  return (
    <div>
      <BreadCrumb title={"Caisse"} description={"Caisse"}/>
      <section className="checkout spad">
        <div className="container">
          <div className="checkout__form">
            <form onSubmit={handleSubmit(proceedToCheckout)}>
              <div className="row">
                <div className="col-lg-8">
                  <div className="checkout__form__top">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <h4>Informations</h4>
                      </div>
                    </div>
                  </div>
                  <div className="input__top">
                    <div className="row">

                      <div className="col-md-12">
                        <input
                          name={'email'}
                          className={errors?.email?.type === "required" || errors.email?.type === 'pattern' ? 'required' : ''} {...register("email", {
                          required: true,
                          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                        })}
                          placeholder="Adresse courriel"/>
                        {errors?.email?.type === "required" &&
                        <p className={'missing-field-error'}>Champs obligatoire</p>}
                        {errors?.email?.type === "pattern" &&
                        <p className={'missing-field-error'}>Veuillez entrer une adresse courriel valide</p>}
                      </div>
                      <p className={"container"}>Ces informations seront utilisées seulement à des fins de contact.</p>
                    </div>
                    <div className="input__top">

                      <div className="row">

                        <div className="col-lg-6">
                          <div className="input__item">
                            <p>Prénom<span>*</span></p>
                            <input type="text"
                                   name={'first_name'}
                                   className={errors?.firstName?.type === "required" ? 'required' : ''}  {...register("firstName", {required: true})}
                                   placeholder={"Prénom"}/>
                            {errors?.firstName?.type === "required" &&
                            <p className={'missing-field-error'}>Champs obligatoire</p>}

                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input__item">
                            <p>Nom<span>*</span></p>
                            <input type="text"
                                   name={'last_name'}
                                   className={errors?.lastName?.type === "required" ? 'required' : ''}  {...register("lastName", {required: true})}
                                   placeholder={'Nom'}/>
                            {errors.lastName?.type === "required" &&
                            <p className={'missing-field-error'}>Champs obligatoire</p>}

                          </div>
                        </div>
                      </div>
                    </div>
                    {/*<label htmlFor="kp">*/}
                    {/*  Rester informé des offres et promotions*/}
                    {/*  <input type="checkbox"  {...register("newsletter")} id="kp"/>*/}
                    {/*  <span className="checkmark"/>*/}
                    {/*</label>*/}
                    <input type="text" name={'phone'} {...register("phone")} placeholder="Numéro de téléphone"/>
                  </div>

                  <form>
                    <div className="selector-container">
                      <div className="selector-box" onClick={() => setIsShipping(true)}>
                        {isShipping && <Check class={'check'} fill={'pink'} width={50}/>}
                        <h4>Livraison</h4>
                        <p>Recevez votre colis en 3-5 jours ouvrables</p>
                      </div>
                      <div className="selector-box" onClick={() => setIsShipping(false)}>
                        {!isShipping && <Check class={'check'} fill={'pink'} width={50}/>}
                        <h4>Récupérer</h4>
                        <p>Récupérez votre colis dans notre succursale de Lavaltrie</p>
                      </div>
                    </div>
                  </form>

                  {isShipping && shippingInfos()}

                </div>
                <div className="col-lg-4">
                  <div className="checkout__proceed">
                    <div className="checkout__proceed__title">
                      <h4>Votre commande</h4>
                    </div>
                    {/*<div className="checkout__proceed__coupon">*/}
                    {/*  <p>Entrez votre coupon. </p>*/}
                    {/*  <div className="coupon__input">*/}
                    {/*    <input type="text" className={errors?.email?.type === "required" ? 'required' : ''} onChange={onPromoCodeChange} placeholder="Votre code ici"/>*/}
                    {/*    <button disabled={validPromoCode !== null} onClick={applyPromoCode}>APPLIQUER</button>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="checkout__proceed__product">
                      <h5>Produits</h5>
                      <ul>
                        {state && state.products.map((product) => (
                          <li>{product.count} x {product.title} ({product.price && product.price.toFixed(2)}$)
                            <span>{product.count && product.price && (product.price * product.count).toFixed(2)}$</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {/*{discount && validPromoCode && <div className="checkout__proceed__product"><h5>Promotion</h5>*/}
                    {/*  <ul>*/}
                    {/*    <li>{validPromoCode.name.toUpperCase()} <span>- {discount.toFixed(2)}$</span></li>*/}
                    {/*  </ul>*/}
                    {/*</div>}*/}
                    {subTotal < 60 && <div className="checkout__proceed__product">
                      {isShipping && <ul>
                        <li>Livraison <span>{constants.SHIPPING_PRICE.toFixed(2)}</span></li>
                      </ul>}
                    </div>}
                    <ul className="checkout__proceed__total">
                      <li>Sous-total <span>{subTotal.toFixed(2)}$</span></li>
                      <li>TPS <span>{(0.05 * subTotal).toFixed(2)}$</span></li>
                      <li>TVQ <span>{(0.09975 * subTotal).toFixed(2)}$</span></li>
                      <li>Total <span>{(subTotal * 1.14975).toFixed(2)}$</span></li>
                    </ul>
                    <div className="checkout__proceed__widget">
                      <button disabled={state.products.length === 0} onClick={proceedToCheckout}>
                        Passer à la caisse
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  )
};

export default CheckoutPage;
