
const {constants} = require("../constants");
const getFinalPrice = (products, promoCode) => {
  const cartPrice = products.reduce((a, b) => a + (b['price'] * b['count'] || 0), 0)
  let finalPrice = cartPrice //- getDiscountAmount(products, promoCode)
  if(cartPrice < 60) finalPrice += 10
  return finalPrice * 1.14975
}

// const getDiscountAmount = (products, promoCode) => {
//   if(!promoCode) return 0
//   let discount = 0
//   const cartPrice = products.reduce((a, b) => a + (b['price'] * b['count'] || 0), 0)
//   if(promoCode.type === 'percentage') {
//     discount = (promoCode.amount/100)*cartPrice
//   } else if (promoCode.type === 'price') {
//     discount = promoCode.amount
//   }
//   return !promoCode.minimum_price || promoCode.minimum_price <= cartPrice ? discount : 0
// }

const getSubTotal = (products, promoCode, isShipping) => {
  let cartPrice = products.reduce((a, b) => a + (b['price'] * b['count'] || 0), 0)
  // const discount = getDiscountAmount(products, promoCode)
  if(cartPrice < 60 && isShipping) cartPrice += constants.SHIPPING_PRICE
  return cartPrice
}

module.exports = {
  getFinalPrice,
  getSubTotal,
  // getDiscountAmount
}