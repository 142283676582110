import React from 'react';
import {Link} from "react-router-dom";
import logo from '../assets/lsdc-logo-white.png'
import '../Styles/_footer.scss'

const FooterComponent = (props) => {
  return (
      <footer className="footer">
        <div className="container">
          <div className="footer__top">
            <div className="row">
              <div className="col-lg-3 col-md-3">
                <div className="footer__logo">
                  <a href="#"><img className={"footer-logo"} src={logo} alt=""/></a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="footer__top__text">
                  <p>Entreprise de produits artisanaux qui est née d’une passion pour la fabrication de produits pour le
                    corps, fabriqué à la main.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="footer__top__social">
                  <a href="https://www.facebook.com/Les-savons-de-C%C3%A9line-105626911462549"><i
                      className="fa fa-facebook"/></a>
                  {/*<a href="#"><i className="fa fa-twitter"/></a>*/}
                  <a href="https://www.instagram.com/lessavonsdeceline/"><i className="fa fa-instagram"/></a>
                  {/*<a href="#"><i className="fa fa-linkedin"/></a>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="footer__options">
            <div className="row">
              <div className="col-lg-2 col-md-3">
                <div className="footer__widget">
                  <h4 style={{fontFamily: 'Kitchen Home, sans-serif', fontSize: '25px', fontWeight: 'bold'}}>Compagnie</h4>
                  <ul>
                    <li><a href="#">Á propos de nous</a></li>
                    <li><a href="#">Services</a></li>
                    <li><a href="#">Nous contacter</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-3">
                <div className="footer__widget">
                  <h4 style={{fontFamily: 'Kitchen Home, sans-serif', fontSize: '25px', fontWeight: 'bold'}}>Compte</h4>
                  <ul>
                    <li><Link to={"/cart"}>Mon panier</Link></li>
                    {/*<li><a href="#">Login/Register</a></li>*/}
                  </ul>
                </div>
              </div>
              {/*// <div className="col-lg-4 col-md-6">*/}
              {/*//   <div className="footer__newslatter">*/}
              {/*//     <h4>Newletter</h4>*/}
              {/*    <p>Subcribe to our newsletter to get more free tips. No Spam, Promise.</p>*/}
              {/*    <form action="#">*/}
              {/*      <input type="text" placeholder="Eamil"/>*/}
              {/*      <button type="submit">Subscribe</button>*/}
              {/*    </form>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="col-lg-4 col-md-6">
                <div className="footer__widget footer__widget--address">
                  <h4 style={{fontFamily: 'Kitchen Home, sans-serif', fontSize: '25px', fontWeight: 'bold'}}>Entrer en contact</h4>
                  <ul>
                    {/*<li>69 North Cleveland Street, Memphis,USA.</li>*/}
                    {/*<li>(123) 8111 9210 - (012) 1111 6868</li>*/}
                    <li>celineperrier@outlook.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__copyright">
            <div className="row">
              <div className="col-lg-12 text-center">
                <p>Copyright &copy; 2023

                  Tous droits réservés | <a
                      href="https://colorlib.com" target="_blank" rel="nofollow noopener"><i
                      className="fa fa-heart color-danger"
                      aria-hidden="true"/></a>
                </p>

              </div>
            </div>
          </div>
        </div>
      </footer>
  )
};
export default FooterComponent;
