import React, {useEffect, useState} from 'react';
import HeroesServices from "../Services/hero";

const BenefitsComponent = props => {
  const [benefits, setBenefits] = useState([])
  useEffect(() => {
    HeroesServices.getBenefits()
        .then((response) => {
          setBenefits(response.data)
        })
        .catch((err) => {
          console.warn(err)
        })
  }, [])

  return benefits.length > 0 ? (
      <div className="benefits">
        <div className="container">
          <div className="row">
            {benefits.map((benefit, index) => (
                <div key={index} className="col-lg-3 col-md-6 col-sm-6">
                  <div className="benefits__item">
                    <div className="benefits__item__icon">
                      <img style={{marginTop: index === benefits.length - 1 ? '-15px' : 0}} src={benefit.icon.url} alt=""/>
                    </div>
                    <div className="benefits__item__text">
                      <h6>{benefit.title}</h6>
                      {/* <p>{benefit.description}</p> */}
                    </div>
                  </div>
                </div>
            ))}
          </div>
        </div>
      </div>
  ) : <></>
};

export default BenefitsComponent;
