import {useEffect, useState} from 'react'
import HeroesServices from "../Services/hero";
export default function BreadCrumb(props) {
  const [url, setUrl] = useState(null)
  useEffect(() => {
    HeroesServices.getBanner()
        .then((response) => {
          setUrl(response.data[0].picture.url)
        })
        .catch((err) => {
          setUrl('../img/breadcrumb-bg.jpg')
        })
  }, [])

  return <div className="breadcrumb-option set-bg" style={{'background-image': `url(${url})`}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <div className="breadcrumb__text">
            <h2>{props.title}</h2>
            <div className="breadcrumb__links">
              <a href="/">Accueil</a>
              <span>{props.description}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
}
