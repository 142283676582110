import '../Styles/ProductDetails.css'

import {useState, useEffect} from 'react'
import { constants } from "../constants";

const ProductImages = (props) => {
  const {images} = props;

  const [activeImage, setActiveImage] = useState(images[0])

  useEffect(() => {
    setActiveImage(props.images[0])
  },[props])

  return (
    <div className="product__details__pic images-container">
      <div className="product__details__pic__item">
        {<img src={`${activeImage.url}`} alt=""
              className="product__details__big__pic"/>}
      </div>
      <div className="product__details__thumb nice-scroll right-images-frame">
        {images.length > 1 && images.map((picture, key) => {
          return (
            <div className="pt__item" key={key} onClick={() => {setActiveImage(picture)}}>
              <img data-imgbigurl="img/product/details/product-details-2.jpg"
                   src={`${picture.url}`} alt=""/>
            </div>
          )
        })}
      </div>
    </div>)
}

export default ProductImages;
