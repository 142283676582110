import React, {useState, useEffect} from "react";
import CategoriesServices from "../Services/categories";
import ProductsServices from "../Services/products";
import Product from "./product";
import '../Styles/animation.css'
import {CSSTransition, TransitionGroup} from "react-transition-group";

export default function HomeMarket() {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [activeCategory, setActiveCategory] = useState({id: -1});
  const [isDisplayAll, setIsDisplayAll] = useState(false);
  useEffect(() => {
    CategoriesServices.getCategories()
      .then((response) => {
        setCategories(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
    ProductsServices.getProducts()
      .then((response) => {
        setProducts(response.data);
        setAllProducts(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (activeCategory.id === -1) {
      setProducts(allProducts);
    } else {
      setProducts(allProducts.filter((product) => (
        product.categories.filter((category) => (category.id === activeCategory.id)).length > 0)
      ))
    }
  }, [activeCategory]);

  const categoryClicked = (category) => {
    setProducts([])
    setTimeout(() => {
      setActiveCategory(category);
    },250)
  }

  return (
    <section className="arrival spad">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="section-title">
              <span>Nos produits</span>
              <h2 style={{fontFamily: 'Baby Names', fontSize: '60px'}}>Nouveaux arrivages</h2>
            </div>
            <div className="filter__controls">
              <ul>
                <li
                  onClick={() => {
                    setActiveCategory({id: -1});
                  }}
                  className={activeCategory.id === -1 ? "active" : ""}
                  data-filter="*"
                >
                  Tous
                </li>
                {categories.map((category, key) => (
                  <li
                    key={key}
                    className={
                      activeCategory.id === category.id ? "active" : ""
                    }
                    onClick={() => {
                      categoryClicked(category)
                    }}
                    data-filter={`.${category.name}`}
                  >
                    {category.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {/*<div class="row product__filter">*/}
        <TransitionGroup
          className={'row product__filter'}>
          {isDisplayAll ? products.map((product, key) => {
            return (<CSSTransition
              key={`${product.name}-${key}`}
              classNames="product"
              timeout={500}
            >
              <Product product={product} classes={`mix animation product`}/>
            </CSSTransition>)
          }) : 
          products.slice(0,10).map((product, key) => {
            return (<CSSTransition
              key={`${product.name}-${key}`}
              classNames="product"
              timeout={500}
            >
              <Product product={product} classes={`mix animation product`}/>
            </CSSTransition>)
          })
          }
        </TransitionGroup>
      </div>
      {!isDisplayAll && <div className="row">
            <div className="col-lg-12 text-center">
              <div className="view__all">
                <a onClick={() => {setIsDisplayAll(true)}}>Afficher plus</a>
              </div>
            </div>
          </div>}
      {/*</div>*/}
    </section>
  );
}
