import axios from "./api";

export default class ProductsServices {
  static getProducts = () => {
    return axios.get('products/')
  }
  static getProduct = (id) => {
    return axios.get(`products/${id}`)
  }
}
