import React, {useState, useEffect} from 'react';
import AboutComponent from "../Components/AboutComponent";
import ServicesComponent from "../Components/ServicesComponent";
import CommentsComponent from "../Components/CommentsComponent";
import AboutServices from "../Services/about";

const AboutPage = () => {
  const [services, setServices] = useState(null)
  const [comments, setComments] = useState(null)
  const [infos, setInfos] = useState(null)
  const [commentsBackgroundImage, setCommentsBackgroundImage] = useState(null)

  useEffect(() => {
    AboutServices.getInfos()
        .then((response) => {
          setServices(response.data.Services)
          setComments(response.data.Comments)
          setInfos(response.data)
          setCommentsBackgroundImage(response.data.commentsBackgroundImage)
        })
        .catch((err) => {
          console.log(err);
        })

  }, [])

  return (
      <>
        <AboutComponent infos={infos}/>
        <ServicesComponent services={services}/>

        <CommentsComponent backgroundImage={commentsBackgroundImage} comments={comments}/>
      </>
  );
};

export default AboutPage;