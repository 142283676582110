import * as React from 'react'

const CartContext = React.createContext()

const getProductIndex = (products, product) => {
  if (product.size) {
    return products.findIndex((a) => a.id === product.id && product.size.value === a.size.value)
  } else if (product.shape) {
    return products.findIndex((a) => a.id === product.id && product.shape.value === a.shape.value)
  } else {
    return products.findIndex((a) => a.id === product.id)
  }
}

function countReducer(state, action) {
  switch (action.type) {
    case 'addProduct':
      const productIndex = getProductIndex(state.products, action.product)
      if (productIndex >= 0) {
        const productCount = action.product.count ? action.product.count : 1
        return {
          products: [...state.products.slice(0, productIndex),
            {...state.products[productIndex], count: state.products[productIndex].count + productCount},
            ...state.products.slice(productIndex + 1)]
        }
      }
      return {products: [...state.products, {...action.product, count: action.product.count ? action.product.count : 1}]};
    case 'clearCart':
      return {products: []}
    case 'decreaseProductCount':
        const i = getProductIndex(state.products, action.product)
      if (state.products[i].count - 1 > 0) {
        return {
          products: [...state.products.slice(0, i),
            {...state.products[i], count: state.products[i].count - 1},
            ...state.products.slice(i + 1)]
        }
      } else {
        return {
          products: [...state.products.slice(0, i),
            ...state.products.slice(i + 1)]
        }
      }
    case 'increaseProductCount':
      // const index = state.products.findIndex((a) => a.id === action.product.id)
        const index = getProductIndex(state.products, action.product)
      return {
        products: [...state.products.slice(0, index),
          {...state.products[index], count: state.products[index].count + 1},
          ...state.products.slice(index + 1)]
      }
    case 'removeProduct':
      if (action.product.shape) {
        return {products: [...state.products.filter((a) => a.id !== action.product && a.shape.value !== action.product.shape.value)]}
      } else if (action.product.size) {
        return {products: [...state.products.filter((a) => a.id !== action.product && a.size.value !== action.product.size.value)]}
      } else {
        return {products: [...state.products.filter((a) => a.id !== action.product.id)]}
      }
      break;
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function CartProvider({children}) {
  const [state, dispatch] = React.useReducer(countReducer, localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {products: []})
  const value = {state, dispatch}


  // React.useEffect(() => {
  //   if(localStorage.getItem('cart')) {
  //     setState(localStorage.getItem('cart'));
  //   }
  // }, []);

  React.useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(state));
  }, [state]);

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

function useCount() {
  const context = React.useContext(CartContext)
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider')
  }
  return context
}

export {CartProvider, useCount}
