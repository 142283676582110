import React from 'react';
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ProductsServices from "../Services/products";
import BreadCrumb from "../Components/BreadCrumb";
import '../Styles/_product.scss';
import '../Styles/style.scss';
import ProductImages from "../Components/ProductImages";
import Product from "../Components/product";
import DescriptionPanel from "../Components/DescriptionPanel";
import CartComponent from "../Components/CartComponent";
import {getAvailability, getPrice} from "../utils/productUtils";
import OutOfStockComponent from "../Components/OutOfStockComponent";


const ProductPage = (props) => {

  const [product, setProduct] = useState("")
  const [isAvailable, setIsAvailable] = useState(false)

  useEffect(() => {
    if(product) setIsAvailable(getAvailability(product))
  }, [product])

  const [id, setId] = useState(useParams().id)

  useEffect(() => {
    ProductsServices.getProduct(id)
      .then((response) => {
        setProduct(response.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [id])

  useEffect(() => {
    // getParams()
    setId(props.match.params.id)
  }, [props.match])

  return (
    <>
      <BreadCrumb title={"Produits"} description={"Détails"}/>
      <section className="product-details spad">
        <div className="container">
          <div className="product__details__content">
            <div className="row">
              <div className="col-lg-6">
                {product.pictures && <ProductImages images={product.pictures}/>}
              </div>
              <div className="col-lg-6">
                <div className="product__details__text">
                  <div className="product__details__title">
                    <span>{product.categories && product.categories[0] && product.categories[0].name}</span>{/*TODO*/}

                    <h3>{product.title}</h3>
                    <p>{product.description}</p>
                  </div>
                  {product && <div className="product__details__price">{getPrice(product)}$</div>}
                  <ul className="product__details__widget">
                    {/*{product.categories && product.categories.length > 0 &&  <li>Categorie: <span>{product.categories[0].name}</span></li>}*/}
                    {isAvailable && <>
                      <h4 style={{fontFamily: 'Kitchen Home, sans-serif', fontWeight: 'bold', fontSize: '25px'}}>
                        Disponibilité: {product && !product.shapes.length && !product.sizes.length ? product.quantity : ''}
                      </h4>
                      <br/>
                      {product && product.shapes && product.shapes.length > 0 && product.shapes.map((shape) => (
                        <li>
                          <span>{shape.quantity > 0 ? `${shape.name} ${shape.quantity} disponibles` : "NON DISPONIBLE"}</span>
                        </li>

                      ))}
                      {product && product.sizes && product.sizes.length > 0 && product.sizes.map((size) => (
                        <li>
                          <span>{size.count > 0 ? `${size.name} ${size.count} disponibles` : "NON DISPONIBLE"}</span>
                        </li>

                      ))}
                      <br/>
                    </>}
                    {!isAvailable && <OutOfStockComponent size={"big"}/>}
                    <li>* Les produits peuvent différer de ceux présentés sur les images.</li>
                  </ul>
                  {isAvailable && <CartComponent isAvailable={isAvailable} product={product}/>}
                  <DescriptionPanel product={product}/>
                </div>
              </div>
            </div>
          </div>
          {product && product.related_products && product.related_products.length > 0 &&
          <div class="related__products">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="section-title">
                  <h2 style={{fontFamily: 'Baby Names', fontSize: '60px'}}>Produits similaires</h2>
                </div>
              </div>
            </div>
          </div>
          }
          <div className="row">
            {product.related_products && product.related_products.map((_product, key) => (
              <Product key={key} product={_product}/>
            ))}
          </div>
        </div>
      </section>
    </>

  )
    ;
};

export default ProductPage;
