import axios from "./api";

export default class CartServices {
  static checkout = (body) => {
    return axios.post('checkout/', body)
  }

  static applyPromoCode = (promoCode) => {
    return axios.post('validate-promo/', {code: promoCode})
  }
}
