import React from 'react';

const OutOfStockComponent = (props) => {
  const {size} = props

  if (size === 'big') {
    return <><h4>En rupture de stock</h4><br/></>
  }

  return (
    <div className={'outOfStock'}>
      <p>Rupture de stock</p>
    </div>
  );
};

export default OutOfStockComponent;