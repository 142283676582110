import moment from "moment";
import {constants} from "../constants";

export const isNew = (product) => {
  const {created_at} = product
  return Math.abs(moment(created_at).diff(moment(), 'days')) < 7
}

export const getCartPrice = (products, isDisplay = true) => {
  const cartPrice = products.reduce((a, b) => a + (b['price'] * b['count'] || 0), 0)
  return cartPrice > 60 || isDisplay ? cartPrice : cartPrice + constants.SHIPPING_PRICE;
}
export const getCartPriceWithTaxes = (products, promo = null) => {
  // if (promo && products.length) {
  //   const total = Number(getCartPrice(products, false) * 1.14975).toFixed(2)
  //   if(promo.type === 'percentage') {
  //     return
  //   }
  //   return
  // }
  // look if the price is greater than 60$ AKA shipping price
  return products.length > 0 ? Number(getCartPrice(products, false) * 1.14975).toFixed(2) : 0;
}

export const getPrice = (product) => {
  if (product.sizes && product.sizes.length > 0) {
    const lowestPrice = getLowestPrice(product.sizes)
    const highestPrice = getHighestPrice(product.sizes)

    if (lowestPrice == highestPrice) {
      return lowestPrice
    }
    return `${lowestPrice.toFixed(2)} - ${highestPrice.toFixed(2)}`
  } else if (product.shapes && product.shapes.length > 0) {
    const lowestPrice = getLowestPrice(product.shapes)
    const highestPrice = getHighestPrice(product.shapes)

    if (lowestPrice == highestPrice) {
      return lowestPrice
    }
    return `${lowestPrice.toFixed(2)} - ${highestPrice.toFixed(2)}`
  } else {
    // console.log(product)
    return product.price.toFixed(2)
  }
}

const getLowestPrice = (sizes) => {
  let price
  sizes.forEach((size, i) => {
    if (i === 0) price = size.price;
    if (size.price < price) price = size.price
  })
  return price
}

const getHighestPrice = (sizes) => {
  let price
  sizes.forEach((size, i) => {
    if (i === 0) price = size.price;
    if (size.price > price) price = size.price
  })
  return price
}

export const getAvailability = (product) => {
  if (product.sizes.length > 0 || product.shapes.length > 0) {
    return product.sizes.reduce((a, size) => a + size.count, 0) + product.shapes.reduce((a, size) => a + size.quantity, 0) > 0
  } else {
    return product.quantity > 0
  }
}

export const getQuantity = product => {
  let quantity = {}
  if (product.sizes.length > 0 || product.shapes.length > 0) {
    product.sizes.forEach((size) => {
      quantity[size.name] = size.count
    })
    product.shapes.forEach((shape) => {
      quantity[shape.name] = shape.quantity
    })
    return quantity
  } else {
    return product.quantity
  }
}
