import React, {useState, useEffect} from "react";
import CategoriesServices from "../Services/categories";
import { useHistory } from 'react-router-dom';


function CategoriesComponent(props) {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    CategoriesServices.getCategories()
        .then((response) => {
          setCategories(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
  }, []);
  let history = useHistory();

  return (
      <div className="categories">
        <div className="container">
          <div className="row">
            {categories.map((category, key) => (
                  <div style={{cursor: "pointer"}} onClick={() => {history.push(`boutique?categorie=${category.id}`)}} className="col-lg-3 col-md-6" key={key}>
                    <div
                        className="categories__item set-bg"
                        style={{
                          backgroundImage: category.picture ? `url(${category.picture.url})` : '',
                        }}
                    >
                      <div className="categories__item__text">
                        <h4 style={{fontFamily: 'Kitchen Home, sans-serif', fontSize: '25px', fontWeight: 'bold'}}>{category.name}</h4>
                        <span>( {category.products.length} articles )</span>
                      </div>
                    </div>
                  </div>
            ))}
          </div>
        </div>
      </div>
  );
}

export default CategoriesComponent;
