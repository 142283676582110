import React, {useEffect, useState} from 'react';
import HeroesServices from "../Services/hero";

function InstagramComponent(props) {

  const [images, setImages] = useState([])

  useEffect(() => {
    HeroesServices.getBottomImages()
        .then((response) => {
          setImages(response.data)
        })
        .catch((err) => {
          console.log('Error while fetching bottom images', err)
        })
  }, [])

  return (
      <div className="instagram">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              {images && images.length > 0 && images.map((image, key) => (
                  <div className="instagram__post" key={key}>
                    <div className="instagram__post__item set-bg"
                         style={{backgroundImage: `url(${image.picture.url})`}}>
                      <div className="instagram__post__item__hover">
                        {key % 2 > 0 ?
                            <>
                              <i className="fa fa-instagram"/>
                              <h6>Suivez-nous <a
                                  href="https://www.instagram.com/lessavonsdeceline/">@lessavonsdeceline</a></h6>
                            </>
                            : <>
                              <i className="fa fa-facebook"/>
                              <h6>Suivez-nous <a
                                  href="https://www.facebook.com/Les-savons-de-C%C3%A9line-105626911462549">Les savons
                                de Céline</a></h6>
                            </>}
                      </div>
                    </div>
                  </div>
              ))
              }
            </div>
          </div>
        </div>
      </div>
  );
}

export default InstagramComponent;