import React from 'react';

const ErrorPage = () => {
  return (
      <div>
        <h1>Oops.. Une erreur est survenue.</h1>
        <p>Un problème est survenue avec votre commande. Réessayer plus tard ou contactez-nous.</p>
      </div>
  );
};

export default ErrorPage;