import React, {useState, useEffect} from 'react';
import Slider from "react-animated-slider";

const CommentsComponent = (props) => {
  const {comments} = props;
  console.log(props.backgroundImage)
  return (
      <div className="testimonial spad set-bg"
           style={{backgroundImage: props.backgroundImage ? `url(${props.backgroundImage[0].formats.small.url})` : ''}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="testimonial__slider owl-carousel">
                <Slider infinite={1}>
                  {comments && comments.map((comment) => (
                      <div className="center">
                        <div className="testimonial__item">
                          <div className="row d-flex justify-content-center">
                            <div className="col-lg-7">
                              <div className="testimonial__text">
                                {/*<img src="img/testimonial/quote.png" alt=""/>*/}
                                {comment.description && <p>“{comment.description}”</p>}
                                <h4>{comment.personName}</h4>
                                {/*<span>Businessman</span>*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default CommentsComponent;