import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';

const SuccessPage = () => {
  const [countDown, setCountDown] = useState(1000)
  let history = useHistory()
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      } else if (setCountDown === 0) {
        clearInterval(myInterval)
      }
    }, 10)
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (countDown === 0) {
      history.push('/')
    }
  }, [countDown])

  return (
      <div>
        <br/>
        <br/>
        <br/>
        <h1>Votre achat a été effectué avec succès!</h1>
        <p>Merci de nous faire confiance avec votre commande. Un courriel de confirmation vous sera transmi sous
          peu.</p>
        <br/>
        <p>Vous serez redirigé dans {Number(countDown/100).toFixed(0)} secondes</p>
        <br/>
        <br/>
        <br/>
      </div>
  );
};

export default SuccessPage;