import React, {useState} from "react";
import {isNew, getPrice, getAvailability} from "../utils/productUtils";
import {Link} from "react-router-dom";
import {useCount} from "../Contexts/cart-context";
import "../Styles/_product.scss";
import {
  Content,
  Header,
  Dialog,
  Divider,
  Heading,
  DialogContainer
} from '@adobe/react-spectrum';
import {useToasts} from 'react-toast-notifications'

import CartComponent from "./CartComponent";
import OutOfStockComponent from "./OutOfStockComponent";

export default function Product(props) {
  const {dispatch} = useCount();
  const {product} = props;

  const {addToast} = useToasts()

  const isAvailable = getAvailability(product)

  const addToCart = () => {
    dispatch({type: "addProduct", product, count: 1});
    addToast('Article ajouté au panier avec succès', {
      appearance: 'success',
      autoDismiss: true,
    })
  };

  const increaseClicked = () => {
    dispatch({type: "increaseProductCount", product});
  };

  const decreaseClicked = () => {
    dispatch({type: "decreaseProductCount", product});
  };

  const removeFromCart = () => {
    dispatch({type: "removeProduct", product});
  };

  let [isOpen, setOpen] = useState(false);
  const closeDialog = () => {
    setOpen(false)
  }

  const openDialog = () => {
    setOpen(true)
  }

  if (props.type === "cart") {
    return (
      <tr>
        <td className="cart__item">
          <div className="cart__item__pic">
            <img
              src={product.pictures[0].formats.thumbnail.url}
              alt=""
            />
          </div>
          <div className="cart__item__text">
            <h4>{product.title}</h4>
            {product.size && <p>{product.size.name}</p>}
            {product.shape && <p>{product.shape.name}</p>}
            <span>{product.price && product.price.toFixed(2)}$</span>
          </div>
        </td>
        <td className="cart__quantity">
          <div className="quantity">
            <div className="pro-qty">
              <span onClick={decreaseClicked} className="dec qtybtn">
                -
              </span>
              <input type="text" value={[product.count]}/>
              <span
                onClick={() => {
                  increaseClicked();
                }}
                className="inc qtybtn"
              >
                +
              </span>
            </div>
          </div>
        </td>
        <td className="cart__price">{(product.price * product.count).toFixed(2)}$</td>
        <td onClick={removeFromCart} className="cart__close">
          <i className="fa fa-times-circle"/>
        </td>
      </tr>
    );
  } else {
    return (
      <div className={`d-col ${props.classes}`}>
        <div className="product__item">
          {/*{!isAvailable && <div className={"out-of-stock"}><p className={""}>Rupture de stock</p></div> }*/}
          <Link to={`/product/${product.id}`}>
            <div
              className="product__item__pic set-bg"
              style={{
                backgroundImage: product.pictures.length ? `url(${product.pictures[0].url})` : '',
              }}
            >
              {isNew(product) && <div className="label">Nouveau</div>}
              <ul className="product__item__hover">
                <li>
                  <a href="#">
                    <span className="icon_search"/>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="icon_bag_alt"/>
                  </a>
                </li>
              </ul>
            </div>
          </Link>
          <div className="product__item__text">
            {!isAvailable && <OutOfStockComponent size={'small'}/>
            }
            <a href="#">
              <h5>
                {product.title}
              </h5>
              <p>
                {product.subtitle}
              </p>
            </a>
            {isAvailable && <>
              <div className="price">{getPrice(product)}$</div>
              {product && (product.shapes || product.sizes) &&
              <div onClick={product.shapes.length > 0 || product.sizes.length > 0 ? openDialog : addToCart}
                   className="cart-btn clearButton">
                Ajouter au panier
              </div>}
              <DialogContainer onDismiss={() => closeDialog} {...props}>
                {isOpen && (
                  <Dialog>
                    <Heading>Choix nécessaire</Heading>
                    <Header><i onClick={closeDialog} style={{size: '15px'}} className="fa fa-times-circle"/>

                    </Header>
                    <Divider/>
                    <Content>
                      <h6>Vous devez choisir une {product.shapes.length > 0 ? 'forme' : 'grosseur'}</h6>
                      <br/>

                      <CartComponent closeDialog={closeDialog} product={product} displayButton={'true'}/>
                    </Content>
                  </Dialog>
                )}
              </DialogContainer>
            </>}
          </div>
        </div>
      </div>
    );
  }
}
