import React from 'react';

const ServicesComponent = (props) => {
  const {services} = props;

  return (
      <div className="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-title">
                <span>Nos services</span>
                <h2 style={{fontFamily: 'Baby Names', fontSize: '6  5px'}}>Les services des Savons de Céline</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {services && services.map((service) => (
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="services__item">
                    <div className="services__item__icon">
                      {service.image && <img src={service.image.url} alt=""/>}
                    </div>
                    <h4 style={{fontFamily: 'Kitchen Home, sans-serif', fontSize: '35px', fontWeight: 'bold'}}>{service.title}</h4>
                    <p>{service.description}</p>
                  </div>
                </div>
            ))}
          </div>
        </div>
      </div>
  );
};

export default ServicesComponent;