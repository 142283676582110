import React, {useEffect, useState} from 'react';
import '../Styles/style.css'
import '../Styles/_product.scss'
import Product from "../Components/product";
import ProductsServices from "../Services/products";
import BreadCrumb from "../Components/BreadCrumb";
import CategoriesServices from "../Services/categories";
import {useLocation} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from "react-transition-group";


export default function Marketplace(props) {
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [activeCategory, setActiveCategory] = useState({id: -1});

  const categoryClicked = (category) => {
    setProducts([])
    setTimeout(() => {
      setActiveCategory(category);
    }, 250)
  }

  useEffect(() => {
    let productsData;
    ProductsServices.getProducts()
        .then((response) => {
          setProducts(response.data.reverse())
          // productsData = response.data
          const params = new URLSearchParams(props.location.search);
          if (Number(params.get('categorie'))) {
            setTimeout(() => {
              setActiveCategory({id: Number(params.get('categorie'))});

            }, 250)
          }
          setAllProducts(response.data);
        })
        .catch((err) => {
          console.error(err)
        })
    CategoriesServices.getCategories()
        .then((response) => {
          setCategories(response.data);
        })
        .catch((err) => {
          console.error(err);
        });

  }, [])

  // useEffect(() => {
  //   const params = new URLSearchParams(props.location.search);
  //   console.log(Number(params.get('categorie')))
  //   if (params.get('categorie')) {
  //     setActiveCategory({id: Number(params.get('categorie'))});
  //     setProducts(allProducts.filter((product) => (
  //         product.categories.filter((category) => (category.id === activeCategory.id)).length > 0)
  //     ))
  //   }
  // }, [])

  useEffect(() => {
    if (activeCategory.id === -1) {
      setProducts(allProducts);
    } else {
      setProducts(allProducts.filter((product) => (
          product.categories.filter((category) => (category.id === activeCategory.id)).length > 0)
      ))
    }
  }, [activeCategory]);
  return (
      <div>
        {/*<img src={backgroundImg} className={'topImage'}/>*/}
        <BreadCrumb title={"Boutique"} description={"Boutique"}/>


        <section className="product spad">
          <div className="container">
            <div className="product__widget">
              <div className="filter__controls">
                <ul>
                  <li
                      onClick={() => {
                        setActiveCategory({id: -1});
                      }}
                      className={activeCategory.id === -1 ? "active" : ""}
                      data-filter="*"
                  >
                    Tous
                  </li>
                  {categories.map((category, key) => (
                      <li
                          key={key}
                          className={
                            activeCategory.id === category.id ? "active" : ""
                          }
                          onClick={() => {
                            categoryClicked(category)
                          }}
                          data-filter={`.${category.name}`}
                      >
                        {category.name}
                      </li>
                  ))}
                </ul>
              </div>
            </div>
            {/*<div className="row">*/}
            {/*{products.map((product, key) => {*/}
            {/*  return <Product key={key} product={product}/>*/}
            {/*})}*/}
            <TransitionGroup
                className={'row product__filter'}>
              {products.map((product, key) => {
                return (<CSSTransition
                    key={`${product.name}-${key}`}
                    classNames="product"
                    timeout={500}
                >
                  <Product product={product} classes={`mix animation product`}/>
                </CSSTransition>)
              })}
            </TransitionGroup>
          </div>
          {/*</div>*/}
        </section>
      </div>
  );
}
