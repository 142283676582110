import axios from "./api";

export default class CategoriesServices {
  static getCategories = () => {
    return axios.get("categories/");
  };
  static getCategorie = (id) => {
    return axios.get(`categories/${id}`);
  };
}
