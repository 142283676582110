import React from 'react';

const AboutComponent = (props) => {
  const infos = props.infos;
  console.log(infos)
  return (
      <div className="about spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div  className="section-title">
                <span>À propos de nous</span>
                {infos && <h2 style={{fontFamily: 'Baby Names, sans-serif', fontSize: '75px'}}>{infos.title}</h2>}
              </div>
            </div>
            <div className="col-lg-7 offset-lg-1">
              <div className="about__top__text">
                {infos && <p >{infos.description}</p>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7">
              {infos && infos.image && <div className="about__video set-bg" style={{backgroundImage: `url(${infos.image[0].url})`}}>
              </div>}
            </div>
            <div className="col-lg-5">
              <div className="about__text">
                {infos && infos.card && infos.card.smallTitle && <span>{infos.card.smallTitle}</span>}
                {infos && infos.card && infos.card.title && <h2>{infos.card.title}</h2>}
                {infos && infos.card && infos.card.description && <p>{infos.card.description}</p>}
                <a href="#" className="primary-btn">Contactez nous</a>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default AboutComponent;