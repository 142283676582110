import React, {useState, useEffect} from "react";
import "../Styles/_hero.scss";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import HeroesServices from "../Services/hero";
import "normalize.css/normalize.css";
import {Link} from "react-router-dom";

function HeroComponent(props) {
  const [content, setContent] = useState([]);

  useEffect(() => {
    HeroesServices.getAll()
        .then((response) => {
          setContent(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
      <section className="hero">
        <div className="hero__slider slider-wrapper">
          <Slider className="slider-wrapper" infinite={1} autoplay={4500}>
            {content.map((item, index) => (
                <div
                    key={index}
                    className="slider-content"
                    style={{
                      background: `url('${item.picture.url}') no-repeat center center`,
                    }}
                >
                  <div className="hero__text inner">
                    <h1 style={{
                      fontFamily: "Baby Names, sans-serif",
                      // fontWeight: 'bolder',
                      color: 'black',
                      fontSize: "9  0px",
                      // marginTop: '-200px'
                    }}>{item.title}</h1>
                    <p>{item.description}</p>
                    <Link to={"boutique"}><button>Magasiner</button></Link>
                  </div>
                  {/*<section></section>*/}
                </div>
            ))}
          </Slider>
        </div>
      </section>
  );
  // return (
  //   <section className="hero">
  //     <div className="hero__slider slider-wrapper">
  //       <Slider duration={2000} autoplay={3000} infinite={true}>
  //         {content.map((item, index) => (
  //           <div
  //             className={'slider-content set-bg'}
  //             key={index}
  //             style={{backgroundSize: 'cover', background: `url('${item.image}') no-repeat center center`}}
  //           >
  //             <div className="container inner">
  //               <div className="row">
  //                 <div className="col-lg-5">
  //                   <div className="hero__text">
  //                     <span>{item.title}</span>
  //                     <h2>{item.description}</h2>
  //                     <a href="#" className="primary-btn">{item.button}</a>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         ))}
  //       </Slider>
  //     </div>
  //   </section>
  // );
}

export default HeroComponent;
