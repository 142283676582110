import './App.scss';
import Header from "./Components/header";
import {BrowserRouter as RootRouter, Route, Switch} from "react-router-dom";
import {CartProvider} from "./Contexts/cart-context";
import {Router} from "./router";
import FooterComponent from "./Components/footer";
import InstagramComponent from "./Components/InstagramComponent";
import {Provider, lightTheme, Button} from '@adobe/react-spectrum';
import {ToastProvider} from 'react-toast-notifications';


function App() {
  return (

      <Provider theme={lightTheme} colorScheme={"light"}>
        <ToastProvider
            autoDismiss
            autoDismissTimeout={6000}>
          <div className="App">
            <div style={{
              display: "flex",
              justifyContent: 'center',
              // height: '45px',
              width: '100%',
              backgroundColor: 'rgb(235,209,224)',
              alignItems: 'center'
            }}><p style={{fontWeight: 'bold', lineHeight: 1, paddingTop: '10px'}}>À l'achat de 60$ obtenez la livraison
              gratuite</p>
            </div>
            <CartProvider>
              <RootRouter>
                <Header/>
                <Router/>
                <InstagramComponent/>
                <FooterComponent/>
              </RootRouter>
            </CartProvider>
          </div>
        </ToastProvider>
      </Provider>
  );
}

export default App;
