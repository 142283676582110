import React, {useState, useEffect} from 'react';
import '../Styles/_header.scss';
import logo from '../assets/lsdc-logo-white.png';
import bagImg from '../img/icon/bag.png'
import {getCartPrice} from "../utils/productUtils";
import {useCount} from '../Contexts/cart-context'
import {NavLink, useLocation} from "react-router-dom";

function Header() {
  const {
    state,
  } = useCount()

  const location = useLocation()

  const [menuMobileActive, setMenuMobileActive] = useState(false)
  const setMobileActive = () => {
    setMenuMobileActive(true)
  }

  useEffect(() => {
    setMenuMobileActive(false)
  }, [location])

  return (
      <>
        <div className={`offcanvas-menu-overlay ${menuMobileActive ? 'active' : ''}`}/>
        <div className={`offcanvas-menu-wrapper ${menuMobileActive ? 'active' : ''}`}>
          <div className="offcanvas__widget">
            <ul>
              <li className={location.pathname === '/cart' ? 'active' : ''}><NavLink activeStyle={{
                fontWeight: "bold",
                color: "red"
              }} to="/cart"><img src={bagImg} alt=""/></NavLink><span>({state.products.length})</span></li>

              <div className="price">{getCartPrice(state.products)}$</div>
            </ul>
          </div>
          <div className="offcanvas__logo">
            <NavLink to="/">
              <img src={logo} className="header-logo"/>
            </NavLink>
          </div>
          <div id="mobile-menu-wrap">
            <div className="slicknav_menu">
            <nav className="slicknav_nav slicknav_hidden">
              <ul>
                <li className={location.pathname === '/' ? 'active' : ''}><NavLink to="/">Accueil</NavLink></li>
                <li className={location.pathname === '/about' ? 'active' : ''}><NavLink to="/about">À propos</NavLink></li>
                <li className={location.pathname === '/boutique' ? 'active' : ''}><NavLink
                    activeClassName={"active"} to="/boutique">Boutique</NavLink></li>
                <li className={location.pathname === '/contact' ? 'active' : ''}><NavLink
                    activeClassName={"active"} to="/contact">Contact</NavLink></li>

              </ul>

            </nav>
            </div>
          </div>
        </div>
        <header className="header">
          <div className="container">
            <div className="row">
              <div className="col-lg-2">
                <div className="header__logo">
                  <a href="/">
                    <img src={logo} className="header-logo"/>
                  </a>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="header__options">
                  <nav className="header__menu mobile-menu">
                    <ul>
                      <li className={location.pathname === '/' ? 'active' : ''}><NavLink to="/">Accueil</NavLink></li>
                      <li className={location.pathname === '/about' ? 'active' : ''}><NavLink to="/about">À propos</NavLink></li>
                      <li className={location.pathname === '/boutique' ? 'active' : ''}><NavLink
                          activeClassName={"active"} to="/boutique">Boutique</NavLink></li>
                      <li className={location.pathname === '/contact' ? 'active' : ''}><NavLink
                          activeClassName={"active"} to="/contact">Contact</NavLink></li>
                    </ul>
                  </nav>
                  <div className="header__option__right">

                    <div className="header__option__right__cart">
                      <ul>
                        <li className={location.pathname === '/cart' ? 'active' : ''}><NavLink activeStyle={{
                          fontWeight: "bold",
                          color: "red"
                        }} to="/cart"><img src={bagImg} alt=""/></NavLink><span>({state.products.length})</span></li>
                      </ul>
                      <div className="price">{getCartPrice(state.products).toFixed(2)}$</div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="canvas__open" onClick={setMobileActive}><i className="fa fa-bars"/></div>
          </div>
        </header>
      </>
  );
}

export default Header;
