import axios from "./api";

export default class HeroesServices {
  static getAll = () => {
    return axios.get("carousels/");
  };

  static getBanner = () => {
    return axios.get('banners/')
  }

  static getBenefits = () => {
    return axios.get('benefits/')
  }

  static getBottomImages = () => {
    return axios.get('bottom-images/')
  }
}
