import {useState, useEffect, useReducer} from 'react'
import '../Styles/_product.scss'
import '../Styles/cart.scss'
import bagImg from '../img/icon/bag-little.png'
import {useCount} from "../Contexts/cart-context";
import Select from 'react-select'
import {useToasts} from 'react-toast-notifications'
import {getQuantity} from "../utils/productUtils";

const CartComponent = (props) => {
  const [counter, setCounter] = useState(1)
  const {addToast} = useToasts()

  const quantity = getQuantity(props.product)

  const increaseClicked = () => {
    if(typeof quantity === 'object') {
      if(size && counter < quantity[size.name]) setCounter(counter + 1)
      if(shape && counter < quantity[shape]) setCounter(counter + 1)
    } else if(counter < quantity){
      setCounter(counter + 1)
    }
  }

  const decreaseClicked = () => {
    if (counter > 0) {
      setCounter(counter - 1)
    }
  }

  const {dispatch} = useCount();

  const addToCart = () => {
    let _product = {...props.product, count: counter};
    if (size) {
      _product = {...props.product, count: counter, size, price: size.price}
    } else if (shape) {
      _product = {...props.product, count: counter, shape}
    }
    dispatch({type: 'addProduct', product: _product})
    if (props.closeDialog) props.closeDialog()
    addToast('Article ajouté au panier avec succès', {
      appearance: 'success',
      autoDismiss: true,
    })
  }

  const shapeOptions = []
  if (props.product.shapes && props.product.shapes.length > 0) {
    props.product.shapes.map((shape) => {
      shapeOptions.push({...shape, value: shape.name, label: shape.name})
    })
  }

  const sizeOptions = []
  if (props.product.sizes && props.product.sizes.length > 0) {
    props.product.sizes.map((size) => {
      sizeOptions.push({...size, value: size.name, label: `${size.name}    |  ${size.price}$`, price: size.price})
    })

  }

  const [shape, setShape] = useState(shapeOptions.length > 0 ? shapeOptions[0] : null)

  const setShapeFunction = (value) => {
    setShape(value)
  }

  const [size, setSize] = useState(sizeOptions.length > 0 ? sizeOptions[0] : null)

  const setSizeFunction = (value) => {
    setSize(value)
  }
// useEffect(() => {
//   setCount(1)
// }, [])

  return (<div className="product__details__btns">
    {props && props.product && props.product.shapes && props.product.shapes.length > 0 && <><br/><Select
      onChange={setShapeFunction}
      value={shape}
      placeholder={'Choisir une forme'} options={shapeOptions}/>
      <br/>
      <br/> </>}
    {props && props.product && props.product.sizes.length > 0 && <>
      <br/>
      <Select onChange={setSizeFunction}
              maxMenuHeight={550}
              value={size}
              placeholder={'Choisir une grandeur'}
              options={sizeOptions}/> <br/>
      <br/> </>}
    <div className="quantity">
      <div className="pro-qty">
        <span onClick={decreaseClicked} className="dec qtybtn">-</span>
        <input type="text" value={counter}/>
        <span onClick={increaseClicked} className="inc qtybtn">+</span>
      </div>
    </div>
    <a onClick={addToCart} className="add_btn clearButton">Ajouter au panier <span><img src={bagImg}
                                                                                        alt=""/></span></a>
    {/*<a href="#" className="heart_btn"><img src="img/icon/heart-little.png" alt=""/></a>*/}
  </div>)

}
export default CartComponent;
