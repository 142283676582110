import {useState} from 'react'

export default function DescriptionPanel(props) {
  const [product] = useState(props.product)
  const [activeTab, setActiveTab] = useState('shipping')

  return <div className="product__details__desc">
    {/*<div className="row">*/}

    <ul style={{display: 'flex', justifyContent: 'center'}} className="nav nav-tabs"
        role="tablist">
      {/*<li className="nav-item">*/}
      {/*  <a onClick={() => setActiveTab('description')}*/}
      {/*     className={`nav-link ${activeTab === 'description' ? 'active' : ''}`} data-toggle="tab"*/}
      {/*     role="tab">Description</a>*/}
      {/*</li>*/}
      <li className="nav-item">
        <a onClick={() => setActiveTab('shipping')} className={`nav-link ${activeTab === 'shipping' ? 'active' : ''}`}
           data-toggle="tab" role="tab">Liste d'ingrédients</a>
      </li>
      {/*<li className="nav-item">*/}
      {/*  <a onClick={() => setActiveTab('reviews')} className={`nav-link ${activeTab === 'reviews' ? 'active' : ''}`}*/}
      {/*     data-toggle="tab" role="tab">Commentaires</a>*/}
      {/*</li>*/}
    </ul>
    {/*</div>*/}
    <div className="tab-content">
      {/*<div className={`tab-pane ${activeTab === 'description' ? 'active' : ''}`} id="tabs-1" role="tabpanel">*/}
      {/*  <p>{props.product.description}</p>*/}
      {/*</div>*/}
      <div className={`tab-pane ${activeTab === 'shipping' ? 'active' : ''}`} id="tabs-2" role="tabpanel">
        <ul style={{listStyle: 'none'}}>

          {props.product.ingredients && props.product.ingredients.length > 0 && props.product.ingredients.map((ingredient, key) => {

            return (
                <li key={key}>
                  <p>{ingredient.name}</p>
                </li>
            )
          })}
        </ul>
        {props.product && props.product.ingredients.length === 0 && <p>Liste d'ingrédients non disponible</p>}

      </div>
      {/*<div className={`tab-pane ${activeTab === 'reviews' ? 'active' : ''}`} id="tabs-3" role="tabpanel">*/}
      {/*  <ul>*/}
      {/*    {props.product.reviews && props.product.reviews.length > 0 && props.product.reviews.map((review, key) => {*/}
      {/*      return (*/}
      {/*        <li key={key}>*/}
      {/*          <p>{review.comment}</p>*/}
      {/*        </li>*/}
      {/*      )*/}
      {/*    })}*/}
      {/*  </ul>*/}
      {/*  {props.products && props.product.reviews.length === 0 && <p>Aucun commentaire</p>}*/}
      {/*</div>*/}
    </div>
  </div>;
}
