import React from 'react';
import BreadCrumb from "../Components/BreadCrumb";
import '../Styles/_cart.scss'
import '../Styles/cart.scss'
import {useCount} from "../Contexts/cart-context";
import Product from "../Components/product";
import {getCartPrice, getCartPriceWithTaxes} from "../utils/productUtils";
import {Link} from "react-router-dom";
import {constants} from "../constants";
import {getSubTotal} from "../utils/cart";

export default function CartPage() {

  const {
    state, dispatch
  } = useCount()

  const clearCart = () => {
    dispatch({type:'clearCart'})
  }
  console.log(state.products)
  return (
    <div>
      <BreadCrumb title={"Panier"} description={"Panier"}/>
      <section className="cart spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="cart__table">
                {state.products.length > 0 && <table>
                  <thead>
                  <tr>
                    <th style={{fontFamily: 'Kitchen Home', fontWeight: 'bold', fontSize: '30px'}}>Produit</th>
                    <th style={{fontFamily: 'Kitchen Home', fontWeight: 'bold', fontSize: '30px'}}>Quantité</th>
                    <th style={{fontFamily: 'Kitchen Home', fontWeight: 'bold', fontSize: '30px'}}>Total</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  {state && state.products && state.products.length > 0 && state.products.map((product) => {
                    return <Product product={product} type={"cart"}/>
                  })
                  }
                  </tbody>
                </table>}
                {state.products.length === 0 && <><h6>Aucun article dans le panier</h6><br/><br/></>}
                <div className="cart__btn">
                  <div className="row">
                    <div className="col-lg-4 col-md-5">
                      <div className="cart__btn__continue">
                        <Link to="boutique"><span className="arrow_back"></span> Continuer de magasiner</Link>
                      </div>
                    </div>
                    {state.products.length > 0 && <div className="col-lg-8 col-md-7" >
                      <div className="cart__btn__right">
                        <a className={"clearButton"} onClick={clearCart}><span className="icon_trash_alt"/>Vider le panier</a>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              {/*<div className="cart__coupon">*/}
              {/*  <p>Entrez le code promotionnel. Il sera appliqué à la caisse.</p>*/}
              {/*  <form action="#">*/}
              {/*    <input type="text" placeholder="Votre code ici"/>*/}
              {/*    <button type="submit">APPLIQUER</button>*/}
              {/*  </form>*/}
              {/*</div>*/}
              <div className="cart__total">
                <h4>Total du panier</h4>
                <ul>
                  <li>Sous-total <span>{getSubTotal(state.products, null, false).toFixed(2)}$</span></li>
                  {/*<li>Total <span>{getCartPriceWithTaxes(state.products)}$</span></li>*/}
                  <li>Total <span>{(getSubTotal(state.products, null, false)*1.14975).toFixed(2)}$</span></li>
                </ul>
                {
                  state.products.length === 0 ?
                  <Link onClick={ (event) => event.preventDefault() } style={{cursor: 'not-allowed'}} to={'checkout'}>
                    PASSER À LA CAISSE
                  </Link>
                  :
                  <Link to={'checkout'}>
                    PASSER À LA CAISSE
                  </Link>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
