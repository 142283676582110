import {Route, Switch} from "react-router-dom";
import Marketplace from "./Pages/marketplace";
import CartPage from "./Pages/CartPage";
import ProductPage from "./Pages/ProductPage";
import ContactPage from "./Pages/ContactPage";
import HomePage from "./Pages/HomePage";
import CheckoutPage from "./Pages/CheckoutPage";
import PaymentPage from "./Pages/PaymentPage";
import {loadStripe} from "@stripe/stripe-js/pure";
import {Elements} from "@stripe/react-stripe-js";
import AboutPage from "./Pages/AboutPage";
import ScrollToTop from "./Components/scrollToTop";
import SuccessPage from "./Pages/successPage";
import ErrorPage from "./Pages/errorPage";

export const Router = () => {
    const stripePromise = loadStripe('pk_live_51JwRscFXN5itcnomgVjyEswP8jDWxNoKDKuoc5r0ZMhhS5dLCwPvMr7QgKsXqJXKEiACjsFujQvJp5xoOshFEJVA00SO3zBUeE');
  return (
      <Elements stripe={stripePromise}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={HomePage}/>
          <Route exact path="/boutique" component={Marketplace}/>
          <Route exact path="/cart" component={CartPage}/>
          <Route exact path="/contact" component={ContactPage}/>
          <Route exact path="/product/:id" component={ProductPage}/>
          <Route exact path="/checkout" component={CheckoutPage}/>
          <Route exact path="/payment" component={PaymentPage}/>
          <Route exact path="/about" component={AboutPage}/>
          <Route exact path="/success" component={SuccessPage}/>
          <Route exact path="/error" component={ErrorPage}/>
        </Switch>
      </Elements>
  )
}

