import React, {useEffect} from "react";
import HeroComponent from "../Components/HeroComponent";
import BenefitsComponent from "../Components/BenefitsComponent";
import CategoriesComponent from "../Components/CategoriesComponent";
import HomeMarket from "../Components/HomeMarket";
import {useLocation} from "react-router-dom";
import {useCount} from "../Contexts/cart-context";

const HomePage = (props) => {
  let location = useLocation();
  const {dispatch} = useCount();
  useEffect(() => {
    console.log(location)
    if(location && location.search && location.search.includes('success')) {
      dispatch({type: "clearCart"});
    }
  },[])
  return (
    <>
      <HeroComponent />
      <BenefitsComponent />
      <CategoriesComponent />
      <HomeMarket />
    </>
  );
};

export default HomePage;
