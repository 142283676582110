const constants = {
  // BASE_URL: process.env.BASE_URL,
  // BASE_URL: 'https://les-savons-de-celine.herokuapp.com',
  BASE_URL: "https://admin.savonsdeceline.ca/",
  // BASE_URL: "http://localhost:1337",
  SHIPPING_PRICE: 10
}

module.exports = {
  constants
}
